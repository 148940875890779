import React, { PureComponent, cloneElement } from 'react';
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  DeleteButton,
  CardActions,
  CreateButton,
} from 'react-admin';
import { withStyles } from '@material-ui/core';
import DotsMenu from '../../../components/DotsMenu/DotsMenu';
import DeleteAlertDialog from '../../../components/DeleteAlertDialog/DeleteAlertDialog';
import FilterCustomer from '../FilterCustomer/FilterCustomer';
import roles from '../../../constants/roles';
import BulkGrantProgram from '../BulkGrantProgram/BulkGrantProgram';

const styles = {
  action: {
    textAlign: 'right',
  },
};

const ListActions = ({
  bulkActions,
  basePath,
  displayedFilters,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
}) => {
  return (
    <CardActions>
      {bulkActions &&
        cloneElement(bulkActions, {
          basePath,
          filterValues,
          resource,
          selectedIds,
          onUnselectItems,
        })}
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <BulkGrantProgram />
    </CardActions>
  );
};

class ListCustomers extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      record: null,
    };
  }

  handleAction = (action, record) => {
    if (action && action.toLowerCase() === 'delete') {
      this.setState({ isOpen: true, record });
    }
    if (action && action.toLowerCase() === 'edit') {
      this.props.history.push(`/users/${record.id}`);
    }
  };

  handleCloseModalDelete = () => {
    this.setState({ isOpen: false, record: null });
  };

  render() {
    const { classes, permissions, ...props } = this.props;
    return (
      <>
        <List
          {...props}
          title="Customers"
          exporter={false}
          bulkActions={false}
          sort={{}}
          filter={{}}
          filters={<FilterCustomer />}
          filterDefaultValues={{ order_by: '-createdAt' }}
          actions={<ListActions />}
        >
          <Datagrid rowClick="show">
            <TextField source="lastName" sortable={false} />
            <TextField source="firstName" sortable={false} />
            <TextField source="email" sortable={false} />
            <FunctionField
              render={(record) =>
                record.subscription && record.subscription.isActive ? 'Yes' : 'No'
              }
              label="Alpha Posse (Y/N)"
            />
            {permissions === roles.superAdmin || permissions === roles.admin ? (
              <FunctionField
                cellClassName={classes.action}
                label=""
                onClick={(e) => {
                  e.stopPropagation();
                }}
                render={(record) => (
                  <DotsMenu
                    onChange={(e) => this.handleAction(e, record)}
                    options={['delete', 'edit']}
                  />
                )}
              />
            ) : null}
          </Datagrid>
        </List>
        <DeleteAlertDialog
          title="Delete customer"
          subtitle="Are you sure you want to delete customer ?"
          description="Customer will be deleted permanently."
          record={this.state.record}
          open={this.state.isOpen}
          handleClose={this.handleCloseModalDelete}
        >
          <DeleteButton
            size="large"
            redirect={() => this.handleCloseModalDelete()}
            basePath={this.props.basePath}
            record={this.state.record}
            resource={this.props.resource}
          />
        </DeleteAlertDialog>
      </>
    );
  }
}

export default withStyles(styles)(ListCustomers);
