/* eslint-disable no-unused-vars, array-callback-return, consistent-return, no-param-reassign */
import React, { PureComponent } from 'react';

import {
  SimpleForm,
  SimpleShowLayout,
  SelectInput,
  LongTextInput,
  ImageInput,
  FileInput,
  FormDataConsumer,
  REDUX_FORM_NAME,
  CREATE,
  UPDATE,
  DELETE,
  required,
  BooleanInput,
  TextInput,
} from 'react-admin';
import { withStyles } from '@material-ui/core';
import { change } from 'redux-form';
import { equipmentSelectableOptions } from '../../../constants/equipment';
import { difficultySelectableOptions } from '../../../constants/difficulty';
import FileFieldCustom from '../../../components/FileFieldCustom/FileFieldCustom';
import ImgField from '../../../components/ImgField/ImgField';
import uploadService from '../../../utils/upload';
import ListField from './ListField';
import IntroScreensComponent from './IntroScreens';
import uploadVideoService from '../../../utils/uploadVideo';
import StoriesInput from './StoriesInput';
import dataProvider from '../../../providers/dataProvider';
import ToolBarEditProgram from './ToolbarEditProgram';
import Loading from '../../../components/Loading/Loading';
import Sessions from '../../Sessions/Sessions/Sessions';
import PublishProgram from './PublishProgram';

const styles = {
  simpleShowLayout: {
    width: 'inherit',
    marginBottom: '20px',
    background: 'white',
    boxShadow:
      '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
  },
  simpleShowLayoutTitle: {
    paddingLeft: '0',
  },
  longerInput: {
    width: '50%',
  },
  input: {
    width: '100%',
  },
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
  publish: {
    float: 'right',
  },
};

class EditFormProgram extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  uploadVideo = (video) => {
    if (video && video.rawFile) {
      return uploadVideoService(video);
    }
    if (video && !video.rawFile) {
      return Promise.resolve({ data: { id: video.id } });
    }
    return Promise.resolve({ data: { id: null } });
  };

  uploadVideoIntroScreen = (introScreens) => {
    const video = introScreens[1] && introScreens[1].video;
    if (video && video.rawFile) {
      return uploadVideoService(video);
    }
    if (video && video.id) {
      return Promise.resolve({ data: { id: video.id } });
    }
    return Promise.resolve(null);
  };

  uploadImg = (img) => {
    if (img && typeof img === 'object') {
      const formData = new FormData();
      formData.append('file', img.rawFile);
      return uploadService(formData);
    }
    return Promise.resolve({ data: { fileName: img } });
  };

  setUpStoriesToProgram = (stories) => {
    if (!stories) {
      return Promise.resolve(null);
    }
    const requests = stories
      .map((res) => {
        if (res.id && res.text && res.author) {
          return dataProvider(UPDATE, `programs/${this.props.record.id}/stories`, {
            id: res.id,
            data: res,
          });
        }
        if (!res.id && res.text) {
          return dataProvider(CREATE, `programs/${this.props.record.id}/stories`, { data: res });
        }
        if (res.id && !res.text && !res.author) {
          return dataProvider(DELETE, `programs/${this.props.record.id}/stories`, {
            id: res.id,
            data: res,
          });
        }
      })
      .filter((res) => res);
    return Promise.all(requests);
  };

  save = (data, redirect) => {
    this.setState({ loading: true });
    const { newPicture, stories, ...dataCache } = { ...data };

    const promiseArray = [this.uploadImg(newPicture)];
    if (dataCache.isExternal) {
      dataCache.complexity = 0;
      dataCache.equipment = 0;

      delete dataCache.allowedTags;
      delete dataCache.bullets;
      delete dataCache.forbiddenTags;
      delete dataCache.introScreens;
      delete dataCache.isDefault;
      delete dataCache.isPublic;
      delete dataCache.sessions;
      delete dataCache.storiesUrl;
    } else {
      delete dataCache.externalLink;
      delete dataCache.isAlphaPosse;

      promiseArray.push(
        this.uploadVideoIntroScreen(dataCache.introScreens),
        this.setUpStoriesToProgram(stories),
      );
    }

    Promise.all(promiseArray)
      .then((res) => {
        dataCache.picture = res[0].data.fileName;
        if (res[1]) {
          dataCache.introScreens[1].videoId = res[1].id;
        }
        if (
          !dataCache.isExternal &&
          dataCache.introScreens[1].video &&
          dataCache.introScreens[1].videoId
        ) {
          const { video, ...newintroScreens1 } = dataCache.introScreens[1];
          dataCache.introScreens[1] = newintroScreens1;
        }
        if (!dataCache.isExternal) {
          dataCache.storiesUrl = dataCache.storiesUrl ? dataCache.storiesUrl : null;
        }

        this.props.save(dataCache, redirect);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  handlePublic = (formData) => {
    formData.isPublic = !formData.isPublic;
    this.setState({ loading: true });
    dataProvider('UPDATE', 'programs', { id: formData.id, data: formData }).finally(() => {
      this.setState({ loading: false });
    });
  };

  getDefault = () => {
    return {
      ...this.props.record,
      newPicture: this.props.record.picture,
    };
  };

  renderPublishButton = (formData) => {
    if (this.props?.record?.isExternal) {
      return null;
    }

    return (
      this.props?.record?.sessions && (
        <PublishProgram
          formData={formData}
          handlePublic={this.handlePublic}
          isSessions={!!this.props.record.sessions.length}
        />
      )
    );
  };

  render() {
    const { classes, record, permissions, ...props } = this.props;
    return (
      <>
        <Loading open={this.state.loading} />
        <SimpleForm
          {...props}
          save={this.save}
          record={record}
          defaultValue={this.getDefault}
          toolbar={<ToolBarEditProgram />}
        >
          <SimpleShowLayout className={classes.simpleShowLayout}>
            <FormDataConsumer>
              {({ formData }) => this.renderPublishButton(formData)}
            </FormDataConsumer>
            <LongTextInput source="name" label="Program Name" validate={required()} />
            <BooleanInput source="isExternal" label="Is External" />
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                if (formData.isExternal) {
                  return (
                    <>
                      <BooleanInput
                        source="isAlphaPosse"
                        label="Is Alpha Posse (allow add/remove access to discourse for this program)"
                        helpText="qwe"
                        {...rest}
                      />
                      <br />
                      <TextInput
                        source="externalLink"
                        label="External Link"
                        validate={required()}
                        {...rest}
                      />
                    </>
                  );
                }

                return (
                  <>
                    <SelectInput
                      label="Equipment"
                      source="equipment"
                      choices={equipmentSelectableOptions}
                      validate={required()}
                      {...rest}
                    />
                    <br />
                    <SelectInput
                      label="Type"
                      source="complexity"
                      choices={difficultySelectableOptions}
                      validate={required()}
                      {...rest}
                    />
                  </>
                );
              }}
            </FormDataConsumer>
            <LongTextInput source="description" label="Description" validate={required()} />
            <ImageInput
              className={classes.inlineBlock}
              source="newPicture"
              label="Add Program Photo"
              accept="image/*"
              validate={required()}
              format={(v) => (typeof v === 'string' ? { picture: v } : v)}
            >
              <ImgField width="100" height="100" source="picture" />
            </ImageInput>
          </SimpleShowLayout>
          <FormDataConsumer>
            {(innerProps) => {
              if (!innerProps?.formData?.isExternal) {
                return (
                  <SimpleShowLayout {...innerProps} className={classes.simpleShowLayoutTitle}>
                    <h2>Buy Program</h2>
                  </SimpleShowLayout>
                );
              }
              return null;
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, dispatch, ...innerProps }) => {
              if (!formData?.isExternal) {
                return (
                  <SimpleShowLayout {...innerProps} className={classes.simpleShowLayout}>
                    <h4>Bullet Points</h4>
                    {formData?.bullets && (
                      <ListField
                        source="bullets"
                        value={formData.bullets}
                        fieldsNumber={4}
                        handleData={(value) => dispatch(change(REDUX_FORM_NAME, 'bullets', value))}
                      />
                    )}
                  </SimpleShowLayout>
                );
              }
              return null;
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, dispatch, ...innerProps }) => {
              if (!formData?.isExternal) {
                return (
                  <SimpleShowLayout {...innerProps} className={classes.simpleShowLayout}>
                    <h4>GMB Stories</h4>
                    <LongTextInput source="storiesUrl" label="URL" />
                    {formData?.id && (
                      <StoriesInput
                        source="stories"
                        value={formData}
                        fieldsNumber={4}
                        handleData={(value) => dispatch(change(REDUX_FORM_NAME, 'stories', value))}
                      />
                    )}
                  </SimpleShowLayout>
                );
              }
              return null;
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {(innerProps) => {
              if (!innerProps?.formData?.isExternal) {
                return (
                  <SimpleShowLayout {...innerProps} className={classes.simpleShowLayoutTitle}>
                    <h2>Build Program</h2>
                  </SimpleShowLayout>
                );
              }
              return null;
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, dispatch, ...innerProps }) => {
              if (!formData?.isExternal) {
                return (
                  <SimpleShowLayout {...innerProps} className={classes.simpleShowLayout}>
                    <h4>Introduction Screens</h4>
                    {formData?.introScreens && (
                      <IntroScreensComponent
                        value={formData}
                        source="introScreens"
                        handleData={(value) => {
                          return dispatch(change(REDUX_FORM_NAME, 'introScreens', value));
                        }}
                      />
                    )}
                  </SimpleShowLayout>
                );
              }
              return null;
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {(innerProps) => {
              if (!innerProps?.formData?.isExternal) {
                return (
                  <SimpleShowLayout {...innerProps} className={classes.simpleShowLayout}>
                    <Sessions
                      source="sessions"
                      values={this.props.record}
                      permissions={permissions}
                    />
                  </SimpleShowLayout>
                );
              }
              return null;
            }}
          </FormDataConsumer>
        </SimpleForm>
      </>
    );
  }
}

export default withStyles(styles)(EditFormProgram);
