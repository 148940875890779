import React, { useState, useEffect } from 'react';
import { Button, CREATE } from 'react-admin';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List as MUIList,
  ListItem,
  ListItemText,
  Checkbox,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import dataProvider from '../../../providers/dataProvider';

const BulkGrantProgram = ({ disabled }) => {
  const fileInputRef = React.useRef();
  const [uploading, setUploading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedPrograms, setSelectedPrograms] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [message, setMessage] = useState('');
  const [userIds, setUserIds] = useState([]);
  const [emails, setEmails] = useState([]);

  useEffect(() => {
    dataProvider('GET_LIST', 'programs', {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'id', order: 'DESC' },
      filter: {},
    }).then((res) => {
      setPrograms(res.data);
    });
  }, []);

  const extractCSVRecordsIntoArray = async (csvFile) => {
    const text = await csvFile.text();
    const rows = text.split('\n');
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailsArray = [
      ...new Set(
        rows
          .map((row) => row.split(',')[0].trim().toLowerCase())
          .filter((email) => email.length > 0 && emailRegex.test(email)),
      ),
    ];
    return emailsArray;
  };

  const getUserIdByEmails = async (emailsArray) => {
    const userIdsArray = await Promise.all(
      emailsArray.map(async (email) => {
        try {
          const { data } = await dataProvider('GET_ONE', 'users', { query: { email } });

          if (data.users && data.users.length > 0) {
            // eslint-disable-next-line
            console.log(`User found for email: ${email} - ${data.users[0].id}`);
            return data.users[0].id;
          }
          // eslint-disable-next-line
            console.log(`User not found for email: ${email}`);
          return null;
        } catch (error) {
          // eslint-disable-next-line
          console.log(`Failed to fetch user ID for email: ${email}`, error);
          return null;
        }
      }),
    );

    return userIdsArray.filter((id) => id !== null);
  };

  const grantProgramsToUsers = async () => {
    try {
      setUploading(true);
      const response = await dataProvider(CREATE, 'programs/bulk-grant', {
        data: {
          userIds,
          programIds: selectedPrograms,
        },
      });
      const usersGranted = response.data.filter((r) => r.programsGranted.length > 0);
      setMessage(`Programs granted successfully to ${usersGranted.length} users`);
      // eslint-disable-next-line
      console.log('results', response.data);
    } catch (error) {
      setMessage('Error granting programs');
    } finally {
      setUploading(false);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const emailsArray = await extractCSVRecordsIntoArray(file);
        setEmails(emailsArray);
        const userIdsList = await getUserIdByEmails(emailsArray);
        setUserIds(userIdsList);
      } catch (error) {
        // eslint-disable-next-line
        console.error('Error processing CSV file:', error);
      }
    }
  };

  const handleToggleProgram = (programId) => {
    setSelectedPrograms((prev) =>
      prev.includes(programId) ? prev.filter((id) => id !== programId) : [...prev, programId],
    );
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedPrograms([]);
    setMessage('');
    setEmails([]);
    setUserIds([]);
  };

  return (
    <>
      <Button onClick={handleOpenModal} label="Bulk grant program" disabled={disabled || uploading}>
        <CloudUploadIcon />
      </Button>

      <Dialog open={openModal} onClose={handleCloseModal} maxWidth="md" fullWidth>
        <DialogTitle>Bulk Grant Program</DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Button
              onClick={() => fileInputRef.current.click()}
              label="Upload CSV"
              disabled={uploading}
            >
              <CloudUploadIcon />
            </Button>
          </div>

          {emails.length > 0 && (
            <div>
              <p>Status: {message}</p>
              <p>{emails.length} unique, valid email(s) loaded</p>
              <p>{userIds.length} user(s) found</p>
              <p>Selected programs: {selectedPrograms.length}</p>
              <MUIList>
                {programs.map((program) => (
                  <ListItem
                    key={program.id}
                    dense
                    button
                    onClick={() => handleToggleProgram(program.id)}
                  >
                    <Checkbox
                      edge="start"
                      checked={selectedPrograms.includes(program.id)}
                      tabIndex={-1}
                      disableRipple
                    />
                    <ListItemText primary={program.name} />
                  </ListItem>
                ))}
              </MUIList>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} label="Cancel" color="secondary">
            <CancelIcon />
          </Button>
          <Button
            onClick={grantProgramsToUsers}
            label="Grant Programs"
            disabled={uploading || !userIds.length || !selectedPrograms.length}
            color="secondary"
          >
            <CheckCircleIcon />
          </Button>
        </DialogActions>
      </Dialog>

      <input
        type="file"
        accept=".csv"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleFileUpload}
      />
    </>
  );
};

export default BulkGrantProgram;
